import { forwardRef, Ref, SelectHTMLAttributes } from 'react'
import styled from 'styled-components'

import { ErrorText, FieldStyles, StyledLabel } from './Field'
import { Icon } from './Icon'
import { Text } from './Text'

export type SelectProps = {
  label?: string
  error?: string
  placeholder?: string
  labelStyles?: React.CSSProperties
} & SelectHTMLAttributes<HTMLSelectElement>

export const Select = forwardRef(function Select(
  { label, error, placeholder, labelStyles, children, ...props }: SelectProps,
  ref: Ref<HTMLSelectElement>
): JSX.Element {
  return (
    <StyledLabel as="label" variant="sixteen" style={labelStyles}>
      {label && (
        <Text as="span" css={{ display: 'block', marginBottom: '0.5rem' }}>
          {label}
        </Text>
      )}
      <Wrapper error={!!error}>
        <IconHolder>
          <Icon icon="caret-down" size={20} className="opacity-70" />
        </IconHolder>
        <StyledSelect
          ref={ref}
          defaultValue={placeholder ? '' : undefined}
          {...props}
        >
          {placeholder && (
            <option value="" disabled hidden>
              {placeholder}
            </option>
          )}
          {children}
        </StyledSelect>
      </Wrapper>
      {error && (
        <ErrorText as="span" variant="fourteen">
          {error}
        </ErrorText>
      )}
    </StyledLabel>
  )
})

const Wrapper = styled.div<{ error?: boolean }>`
  ${FieldStyles}
  width: 100%;

  position: relative;
  line-height: 1.2;
  padding: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  > option {
    color: ${({ theme }) => theme.colors.palette.brand[600]};
  }

  z-index: ${({ theme }) => theme.zIndex.low};
`

const StyledSelect = styled.select`
  display: block;
  position: relative;

  appearance: none;
  background: none;
  border: none;
  color: inherit;
  font: inherit;
  outline: none;

  width: 100%;
  height: 100%;

  padding: 0.875rem 3.25rem 0.875rem 1rem;

  z-index: ${({ theme }) => theme.zIndex.mid};
`

const IconHolder = styled.div`
  z-index: ${({ theme }) => theme.zIndex.high};
  position: absolute;
  margin: auto;
  top: 0.875rem;
  right: 1rem;
  pointer-events: none;
`
