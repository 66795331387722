import { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { IconOptions } from './Icon'
import { OldButton } from './OldButton'
import { Text } from './Text'

export type LabeledButtonProps = HTMLAttributes<HTMLDivElement> & {
  label: string
  buttonLabel: string
  buttonLeftIcon?: IconOptions
  buttonRightIcon?: IconOptions
  buttonIsLoading?: boolean
  isFull?: boolean
}

export const LabeledButton = ({
  label,
  buttonLabel,
  isFull,
  buttonLeftIcon,
  buttonRightIcon,
  buttonIsLoading,
  ...props
}: LabeledButtonProps): JSX.Element => {
  return (
    <Wrapper isFull={isFull} {...props}>
      <LabelHolder>
        <Text variant="fourteen" as="span">
          {label}
        </Text>
      </LabelHolder>
      <OldButton
        size="small"
        variant="solid"
        leftIcon={buttonLeftIcon}
        rightIcon={buttonRightIcon}
        isLoading={buttonIsLoading}
      >
        {buttonLabel}
      </OldButton>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isFull?: boolean }>`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.foreground.default};
  border: 1px solid ${({ theme }) => theme.colors.background.subtle};

  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0.125rem;

  width: ${({ isFull }) => (isFull ? '100%' : 'auto')};
  flex: ${({ isFull }) => (isFull ? '1' : 'inherit')};

  // override button size
  & > button {
    height: 2.125rem;
    border-radius: 1.0625rem;
    padding: 0.5rem 0.75rem;

    flex-grow: 0;
  }
`

const LabelHolder = styled.div`
  margin: 0 1rem;
  min-width: 5rem;

  white-space: nowrap;

  flex: 1;
`
