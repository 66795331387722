import { useRouter } from 'next/router'
import { useEffect } from 'react'
import styled from 'styled-components'

import {
  OldButton,
  ButtonGroup,
  Container,
  LabeledButton,
  Text,
  Theme,
} from 'common/UI'
import { useOptimalLocation } from 'common/hooks/useOptimalLocation'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { setSessionStorage } from 'lib/storage'

const STORAGE_KEY = '__tfp__region__nudge__'

const messages: Record<
  string,
  Record<'question' | 'otherCountries' | 'go', string>
> = {
  da: {
    question: 'Vil du se specifikt indhold til din region?',
    otherCountries: 'Andre lande',
    go: 'Gå',
  },
  de: {
    question: 'Möchten Sie bestimmte Inhalte für Ihre Region anzeigen?',
    otherCountries: 'Andere Länder',
    go: 'Los!',
  },
  en: {
    question: 'Do you want to see specific content for your region?',
    otherCountries: 'Other countries',
    go: 'Go',
  },
  fr: {
    question: 'Voulez-vous voir du contenu spécifique pour votre région?',
    otherCountries: 'Autres pays',
    go: 'Aller',
  },
  it: {
    question: 'Vuoi vedere contenuti specifici per la tua regione?',
    otherCountries: 'Altri paesi',
    go: 'Vai',
  },
  nl: {
    question: 'Wilt u specifieke inhoud voor uw regio zien?',
    otherCountries: 'Andere landen',
    go: 'Gaan',
  },
  nn: {
    question: 'Vil du se spesifikt innhold for regionen din?',
    otherCountries: 'Andre land',
    go: 'Gå',
  },
  pl: {
    question: 'Czy chcesz zobaczyć konkretne treści dla swojego regionu?',
    otherCountries: 'Inne kraje',
    go: 'Wybierz',
  },
  ro: {
    question: 'Doriți să vedeți conținut specific pentru regiunea dvs.?',
    otherCountries: 'Alte țări',
    go: 'Merge',
  },
  sv: {
    question: 'Vill du se specifikt innehåll för din region?',
    otherCountries: 'Andra länder',
    go: 'Gå',
  },
}

type Props = {
  showRegionModal: () => void
  countryCode: string | null
  setHasFlagInStorage: (flag: boolean) => void
  hasFlagInStorage: boolean
  setShouldShowRegionNudge: (show: boolean) => void
  shouldShowRegionNudge: boolean
}

export const RegionNudge = ({
  showRegionModal,
  countryCode,
  hasFlagInStorage,
  setHasFlagInStorage,
  setShouldShowRegionNudge,
  shouldShowRegionNudge,
}: Props): JSX.Element | null => {
  const { push } = useRouter()

  const closeNudge = () => {
    setSessionStorage(STORAGE_KEY, '1')
    setHasFlagInStorage(true)
  }

  const {
    notInOptimal,
    optimalHasRegion,
    optimalDestination,
    optimalDestinationLabel,
  } = useOptimalLocation({ countryCode })

  const currMessages = messages[optimalDestination.split('-')[0] || 'en']

  useEffect(() => {
    const shouldShow = notInOptimal && optimalHasRegion && !hasFlagInStorage

    setShouldShowRegionNudge(shouldShow)
  }, [
    hasFlagInStorage,
    notInOptimal,
    optimalHasRegion,
    setShouldShowRegionNudge,
  ])

  if (shouldShowRegionNudge) {
    return (
      <Theme colorTheme="dark-blue">
        <Wrapper>
          <Holder>
            <Header>
              <Text variant="fourteen" as="p">
                {currMessages.question}
              </Text>
              <SmallCloseButton
                size="micro"
                leftIcon="close"
                variant="ghost"
                onClick={() => closeNudge()}
              />
            </Header>
            <ButtonsHolder>
              <BtnGroup inlineFrom="sm" gap="small">
                <LabeledButton
                  buttonLabel={currMessages.go}
                  label={optimalDestinationLabel || ''}
                  onClick={() => {
                    push(`/${optimalDestination}`)
                  }}
                />
                <OldButton
                  size="small"
                  variant="ghost"
                  onClick={() => showRegionModal()}
                >
                  {currMessages.otherCountries}
                </OldButton>
              </BtnGroup>
              <CloseButton
                size="small"
                leftIcon="close"
                variant="ghost"
                onClick={() => closeNudge()}
              />
            </ButtonsHolder>
          </Holder>
        </Wrapper>
      </Theme>
    )
  }

  return null
}

const Wrapper = styled.aside`
  background-color: ${({ theme }) => theme.colors.background.default};
  ${({ theme }) =>
    dashedLine('bottom', addAlphaToColor(theme.colors.foreground.default, 20))};

  padding: 0.5rem 0;
`

const Holder = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;

  ${({ theme }) => theme.media.md} {
    flex-direction: row;
  }
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 0.5rem;

  ${({ theme }) => theme.media.md} {
    margin-bottom: 0;
  }
`

const ButtonsHolder = styled.div`
  display: flex;
  align-items: stretch;
  width: 100%;

  ${({ theme }) => theme.media.md} {
    width: auto;
    align-items: flex-start;
    margin-left: 2rem;
  }
`

const BtnGroup = styled(ButtonGroup)`
  width: 100%;

  ${({ theme }) => theme.media.md} {
    width: auto;
    flex-wrap: nowrap;
  }
`

const SmallCloseButton = styled(OldButton)`
  margin-left: 2rem;

  width: 1.5rem;
  height: 1.5rem;

  flex-shrink: 0;

  ${({ theme }) => theme.media.md} {
    display: none;
  }
`

const CloseButton = styled(OldButton)`
  display: none;

  ${({ theme }) => theme.media.md} {
    display: inline-flex;
    margin-left: 2rem;
    width: 2.5rem;
    height: 2.5rem;
  }

  ${({ theme }) => theme.media.lg} {
    margin-left: 4rem;
  }
`
