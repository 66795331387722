import { VirtualElement } from '@popperjs/core'

import { Popover } from 'common/UI/Popover'
import { cn } from 'common/utils/tailwind'

type TooltipProps = {
  referenceElement: Element | VirtualElement | null | undefined
  isOpen: boolean
  label: string
}

export const Tooltip = ({
  referenceElement,
  isOpen,
  label,
  ...props
}: TooltipProps) => {
  return (
    <Popover
      isOpen={isOpen}
      placement="top"
      offset={[0, 10]}
      referenceElement={referenceElement}
    >
      <div
        className={cn(
          'flex py-2 px-3 text-background bg-foreground-dark rounded-2 relative',
          'after:absolute after:block after:h-2.5 after:w-2.5 after:left-1/2 after:-translate-x-1/2 after:rotate-45 after:-bottom-1 after:bg-foreground-dark'
        )}
        {...props}
      >
        <p className="text-fourteen">{label}</p>
      </div>
    </Popover>
  )
}
