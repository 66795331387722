import NextImage from 'next/image'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { OldButton, ButtonLink, Icon, Link, Text } from 'common/UI'
import useOutsideClick from 'common/hooks/useOutsideClick'
import { MenuEntryDividerStoryblok, TextLinkStoryblok } from 'common/types'
import { getImageAttributes } from 'common/utils/content'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { useDataContext } from 'lib/dataContext'
import { CMSLink } from 'modules/shared'

type Props = {
  homeUrl: string
  onSearchClick: () => void
}

type DropdownProps = {
  label?: string
  links?: Array<TextLinkStoryblok | MenuEntryDividerStoryblok>
  component: 'text-link' | 'menu-entry'
  link: TextLinkStoryblok
}

const DropdownComponent = ({ label, links }: DropdownProps): JSX.Element => {
  const router = useRouter()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  useOutsideClick(wrapperRef, () => setIsOpen(false))

  useEffect(
    function closeOnNavigate() {
      const handleRouteChange = () => {
        setIsOpen(false)
      }

      router.events.on('routeChangeStart', handleRouteChange)

      return () => {
        router.events.off('routeChangeStart', handleRouteChange)
      }
    },
    [router.events]
  )

  return (
    <div ref={wrapperRef}>
      {label && (
        <NavItem
          size="small"
          variant="text"
          onClick={() => setIsOpen((prev) => !prev)}
          rightIcon="caret-down"
        >
          {label}
        </NavItem>
      )}
      {isOpen && (
        <Dropdown>
          <div css={{ padding: '0.5rem' }}>
            {links?.map((link) => {
              if (link.component === 'menu-entry-divider') {
                return <Divider key={link._uid} />
              }
              return (
                <NavLink
                  key={link._uid}
                  size="small"
                  variant="text"
                  href={link.link}
                >
                  <Text css={{ width: '100%' }} as="span">
                    {link.label}
                  </Text>
                </NavLink>
              )
            })}
          </div>
          <StyledBottom
            alt="Bottom banner"
            css={{ display: 'block' }}
            src="/assets/banner.png"
          />
        </Dropdown>
      )}
    </div>
  )
}

export const DesktopTopNav = ({
  homeUrl,
  onSearchClick,
  ...props
}: Props): JSX.Element | null => {
  const { config, isSubSite } = useDataContext()

  if (!config || !config?.content) {
    return null
  }

  const { menu_logo, menu_links, cta_label, cta_link } = config.content

  return (
    <Wrapper {...props}>
      <TopNavContainer>
        {menu_logo && menu_logo.filename && (
          <Logo>
            <Link href={homeUrl}>
              <NextImage
                {...getImageAttributes(menu_logo)}
                style={{ objectFit: 'cover', height: '100%' }}
                priority
              />
            </Link>
          </Logo>
        )}
        <Nav>
          {menu_links?.map((item) => {
            if (item.component === 'text-link') {
              return (
                <NavItem key={item._uid} size="small" variant="text">
                  <CMSLink size="small" variant="text" href={item.link}>
                    <Text css={{ width: '100%' }} as="span">
                      {item.label}
                    </Text>
                  </CMSLink>
                </NavItem>
              )
            }
            return (
              <div key={item._uid} css={{ position: 'relative' }}>
                <DropdownComponent
                  label={item.label}
                  links={item.links}
                  link={item.link}
                  component={item.component}
                />
              </div>
            )
          })}
        </Nav>
        {cta_link && cta_label && (
          <CMSLink
            size="small"
            variant={isSubSite ? 'accent' : 'solid'}
            href={cta_link}
            css={{ marginRight: '0.5rem' }}
          >
            {cta_label}
          </CMSLink>
        )}
        <OldButton
          variant="text"
          size="small"
          css={{ width: '3rem' }}
          onClick={() => onSearchClick()}
        >
          <Icon icon="search" size={20} />
        </OldButton>
      </TopNavContainer>
    </Wrapper>
  )
}

const StyledBottom = styled.img`
  object-fit: cover;
  height: 0.5rem;
`

const Divider = styled.hr`
  border: none;
  height: 1px;
  ${({ theme }) => dashedLine('bottom', theme.colors.foreground.default)}
`

const Wrapper = styled.header`
  width: 100%;
  ${({ theme }) => dashedLine('bottom', theme.colors.foreground.default)}
  background-color: ${({ theme }) => theme.colors.background.default};
`

const TopNavContainer = styled.div`
  max-width: 90rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 0 auto 0 auto;

  padding: 0.5rem 1.25rem 0.5rem 1.25rem;
`

const Logo = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  flex-shrink: 0;

  img {
    max-height: 2.5rem;
    width: auto;
  }
`

const Dropdown = styled.ul`
  overflow: hidden;
  margin: auto;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  z-index: ${({ theme }) => theme.zIndex.highest};
  margin: auto;

  min-width: 10rem;
  max-width: 20rem;

  border-radius: 0.75rem;
  box-shadow: ${({ theme }) => theme.shadows.medium};
  background: ${({ theme }) => theme.colors.background.default};
`

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
  margin-right: auto;
`

const NavLink = styled(CMSLink)`
  white-space: pre-wrap;
  width: 100%;
  cursor: pointer;
  transition: background-color ${({ theme }) => theme.transitions.ease()};
  display: inline-block;
  min-width: 100%;
  height: auto;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 0.25rem;

  @media (hover: hover) {
    :hover {
      opacity: 1;
      background-color: ${({ theme }) =>
        addAlphaToColor(theme.colors.foreground.default, 8)};
    }
  }
`

const NavItem = styled(ButtonLink)`
  transition: background-color ${({ theme }) => theme.transitions.ease()};
  cursor: pointer;
  margin: 0 0.5rem;
  padding: 0 1rem;
  font-size: 1rem;

  :hover {
    opacity: 1;
    background-color: ${({ theme }) =>
      addAlphaToColor(theme.colors.foreground.default, 8)};
  }

  svg {
    opacity: 0.7;
  }
`
