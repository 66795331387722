import { HTMLAttributes } from 'react'
import styled from 'styled-components'

import { OldButton } from './OldButton'

export type LabeledSwitchProps = Omit<
  HTMLAttributes<HTMLDivElement>,
  'onClick'
> & {
  options: { label: string; value: string }[]
  value: string
  onClick: (value: string) => void
}

export const LabeledSwitch = ({
  options,
  value,
  onClick,
  ...props
}: LabeledSwitchProps): JSX.Element => {
  return (
    <Wrapper {...props}>
      {options.map((option, i) => (
        <OldButton
          key={`${option.value}-${i}`}
          size="small"
          variant={value === option.value ? 'solid' : 'ghost'}
          onClick={() => {
            onClick(option.value)
          }}
        >
          {option.label}
        </OldButton>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div<{ isFull?: boolean }>`
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.foreground.default};
  border: 1px solid ${({ theme }) => theme.colors.background.subtle};

  height: 2.5rem;
  border-radius: 1.25rem;
  padding: 0.125rem;

  // override button size
  & > button {
    height: 2.125rem;
    border-radius: 1.0625rem;
    padding: 0.5rem 0.75rem;

    flex-grow: 0;

    border: none;
  }
`
