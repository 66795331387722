import React, { useState } from 'react'

import { Tag, Tooltip } from 'common/UI'
import { cn } from 'common/utils/tailwind'

interface TagListProps {
  tags: string[]
  maxVisibleTags?: number
  hasColorVariants?: boolean
  hasHiddenTags?: boolean
}

const categoriesColors = (type: string) => {
  switch (type) {
    case 'diagnosis':
      return 'bg-background-secondary-diagnosis'
    case 'treatment':
      return 'bg-background-secondary-treatments'
    case 'donation':
      return 'bg-background-secondary-donation'
    case 'lgbtqia+':
      return 'bg-background-secondary-lgbtqia'
    case 'preservation':
      return 'bg-background-secondary-preservation'
    case 'condition':
      return 'bg-background-secondary-condition'
    case 'advice':
      return 'bg-background-secondary-advice'
    case 'news':
      return 'bg-background-secondary-news'
    default:
      return 'bg-foreground/5'
  }
}

export const TagList: React.FC<TagListProps> = ({
  tags,
  maxVisibleTags = 1,
  hasColorVariants = false,
  hasHiddenTags = false,
}) => {
  const [isTooltipOpen, setTooltipIsOpen] = useState(false)
  const [tagsListRef, setTagsListRef] = useState<HTMLElement | null>(null)

  if (!tags || tags.length === 0) return null

  const visibleTags = tags.slice(0, maxVisibleTags)
  const hiddenTags = tags.slice(maxVisibleTags)
  const hiddenTagsString = hiddenTags
    .map((tag) =>
      tag === 'lgbtqia+'
        ? tag.toUpperCase()
        : tag.charAt(0).toUpperCase() + tag.slice(1)
    )
    .join(', ')

  return (
    <div className="flex flex-wrap items-center gap-1">
      {visibleTags.map((tag, index) => (
        <div
          key={index}
          className={cn(tag === 'lgbtqia+' ? 'uppercase' : 'capitalize')}
        >
          <Tag
            label={tag}
            className={cn(hasColorVariants && categoriesColors(tag))}
          />
        </div>
      ))}

      {hasHiddenTags && hiddenTags.length > 0 && (
        <div ref={setTagsListRef} className="z-10">
          <div className="cursor-pointer">
            <Tag
              onMouseEnter={() => setTooltipIsOpen(true)}
              onMouseLeave={() => setTooltipIsOpen(false)}
              label={`+${hiddenTags.length}`}
            />
          </div>

          <Tooltip
            referenceElement={tagsListRef}
            isOpen={isTooltipOpen}
            label={hiddenTagsString}
          />
        </div>
      )}
    </div>
  )
}
