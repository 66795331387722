import { motion, AnimatePresence } from 'framer-motion'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import styled from 'styled-components'

import { OldButton, Container, Portal, Text, Theme } from 'common/UI'
import { useBodyLock } from 'common/hooks/useBodyLock'
import { groupArrayBy } from 'common/utils/array'
import { removeLeadingAndTrailingSlash } from 'common/utils/string'
import { addAlphaToColor, dashedLine } from 'common/utils/style'
import { useDataContext } from 'lib/dataContext'
import { useTranslation } from 'lib/i18n'
import { ClinicLocation } from 'modules/Blocks/ClinicLocator/useFetchClinics'

type Props = {
  isVisible: boolean
  variant: 'region' | 'clinic' | null
  onClose: () => void
  city: string
  clinics: Array<ClinicLocation | null>
}

export const Selector = ({
  isVisible,
  onClose,
  variant,
  clinics,
}: Props): JSX.Element => {
  const { config, regions: regionsFromContext, alternates } = useDataContext()

  const router = useRouter()

  useBodyLock(!!isVisible)
  const { i18n } = useTranslation()

  useEffect(() => {
    const handleRouteChange = () => {
      onClose()
    }

    router.events.on('routeChangeStart', handleRouteChange)

    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router.events, onClose])

  const parsedData = (regionsFromContext || []).map((link) => {
    // get what's inside parenthesis
    const locale = /\(([^)]+)\)/gim.exec(link.name)?.[1] || ''
    // remove the language and trim whitespace
    const region = link.name.replace(/\(.+\)/gim, '').trim()

    return {
      locale,
      region,
      slug: link.code,
    }
  })
  const regions = groupArrayBy(
    parsedData,
    'slug',
    (slug) => slug.split('-')[1] || 'International'
  )

  return (
    <AnimatePresence>
      {isVisible && (
        <Portal>
          <Theme colorTheme="dark-blue">
            <Overlay
              onMouseDown={onClose}
              onTouchStart={onClose}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0, transition: { delay: 0.1 } }}
              transition={{ ease: 'easeOut', duration: 0.1 }}
            >
              <Holder
                onMouseDown={(e) => {
                  e.stopPropagation()
                }}
                onTouchStart={(e) => {
                  e.stopPropagation()
                }}
                initial={{ y: '-100%' }}
                animate={{ y: 0 }}
                exit={{ y: '-100%' }}
                transition={{
                  type: 'spring',
                  damping: 35,
                  stiffness: 500,
                }}
              >
                <Container>
                  <Header>
                    <Text variant="sixteen" as="h3">
                      {variant === 'region' && i18n('chooseYourCountry')}
                      {variant === 'clinic' && config?.content.clinic_select}
                    </Text>
                    <Close
                      variant="ghost"
                      leftIcon="close"
                      size="small"
                      onClick={onClose}
                    />
                  </Header>
                  {variant === 'region' && (
                    <RegionContent>
                      {Object.keys(regions)
                        .sort()
                        .map((key) => (
                          <Column key={key}>
                            {regions[key].map((item) => {
                              const exactLink = alternates?.find((alt) => {
                                const region = alt.split('/')[0]
                                return region === item.slug
                              })

                              return (
                                <NextLink
                                  key={item.slug}
                                  href={`/${removeLeadingAndTrailingSlash(
                                    exactLink || item.slug
                                  )}`}
                                  passHref
                                  legacyBehavior
                                >
                                  <RegionItem>
                                    <Text variant="eighteen" as="p">
                                      {item.region}
                                    </Text>
                                    <Text
                                      variant="sixteen"
                                      as="p"
                                      color="background.secondaryBlue"
                                    >
                                      {item.locale}
                                    </Text>
                                  </RegionItem>
                                </NextLink>
                              )
                            })}
                          </Column>
                        ))}
                    </RegionContent>
                  )}
                  {variant === 'clinic' && (
                    <RegionContent>
                      {clinics.map((clinic) => {
                        return (
                          <Column key={clinic?.content.name}>
                            {clinic?.content.website_url && (
                              <NextLink
                                href={clinic?.content.website_url}
                                passHref
                                legacyBehavior
                              >
                                <RegionItem>
                                  <Text variant="eighteen" as="p">
                                    {clinic?.content.name}
                                  </Text>
                                  <Text
                                    variant="sixteen"
                                    as="p"
                                    color="foreground.subtle"
                                  >
                                    {clinic?.content.address}
                                  </Text>
                                </RegionItem>
                              </NextLink>
                            )}
                          </Column>
                        )
                      })}
                    </RegionContent>
                  )}
                </Container>
              </Holder>
            </Overlay>
          </Theme>
        </Portal>
      )}
    </AnimatePresence>
  )
}

const Header = styled.div`
  padding: 1rem 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Close = styled(OldButton)`
  width: 2.5rem;
  height: 2.5rem;
`

const RegionContent = styled.div`
  padding-bottom: 2rem;

  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
`

const RegionItem = styled.a`
  display: block;
  margin-bottom: 1rem;

  color: inherit;
  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
    }
  }
`

const Column = styled.div`
  ${({ theme }) =>
    dashedLine('top', addAlphaToColor(theme.colors.foreground.default, 20))};
  padding-top: 1rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

/**
 * Overlay animation
 */

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 99999;

  background-color: rgba(0, 0, 0, 0.3);

  overflow-y: auto;
`

const Holder = styled(motion.div)`
  cursor: default;

  background-color: ${({ theme }) => theme.colors.background.default};

  // pseudo-element to allow the panel to spring
  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    display: block;
    height: 20vh;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.background.default};
  }
`
