import {
  forwardRef,
  InputHTMLAttributes,
  Ref,
  TextareaHTMLAttributes,
} from 'react'
import styled from 'styled-components'

import { Text } from 'common/UI/Text'

import { ErrorText, FieldStyles, StyledLabel } from './Field'

type CommonInputProps = {
  label?: string
  name: string
  placeholder?: string
  error?: string
}

type InputProps = {
  variant: 'input' | 'dob'
} & CommonInputProps &
  InputHTMLAttributes<HTMLInputElement>

type TextAreaProps = {
  variant: 'text-area'
} & CommonInputProps &
  TextareaHTMLAttributes<HTMLTextAreaElement>

export type TextFieldProps = InputProps | TextAreaProps

export const TextField = forwardRef(function TextField(
  { label, name, placeholder, error, variant, ...props }: TextFieldProps,
  ref: Ref<HTMLInputElement | HTMLTextAreaElement>
): JSX.Element {
  if (variant !== 'text-area') {
    const inputProps = props as InputProps

    return (
      <StyledLabel as="label" variant="sixteen">
        {label && <LabelText as="span">{label}</LabelText>}
        <StyledInput
          {...inputProps}
          {...(variant === 'dob' && { type: 'date' })}
          name={name}
          ref={ref as Ref<HTMLInputElement>}
          placeholder={placeholder}
          error={!!error}
        />
        {error && (
          <ErrorText as="span" variant="fourteen">
            {error}
          </ErrorText>
        )}
      </StyledLabel>
    )
  }

  const textAreaProps = props as TextAreaProps
  return (
    <StyledLabel as="label" variant="sixteen">
      {label && <LabelText as="span">{label}</LabelText>}
      <StyledTextarea
        {...textAreaProps}
        name={name}
        ref={ref as Ref<HTMLTextAreaElement>}
        placeholder={placeholder}
        error={!!error}
      />
      {error && (
        <ErrorText as="span" variant="fourteen">
          {error}
        </ErrorText>
      )}
    </StyledLabel>
  )
})

const LabelText = styled(Text)`
  display: block;
  margin-bottom: 0.5rem;
`

const StyledInput = styled.input<{ error?: boolean }>`
  ${FieldStyles}
`

const StyledTextarea = styled.textarea<{ error?: boolean }>`
  ${FieldStyles}

  height: 9rem;
`
