import { cn } from 'common/utils/tailwind'

import { Icon, IconOptions } from './Icon'

export type TagProps = {
  label: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  icon?: IconOptions
  className?: string
}

export const Tag = ({
  label,
  icon,
  onMouseEnter,
  onMouseLeave,
  className,
  ...props
}: TagProps): JSX.Element => {
  return (
    <span
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={cn(
        'w-fit inline-flex items-center justify-center gap-1 py-1 px-2.5 rounded-2',
        'bg-foreground/5 text-sixteen font-regular text-foreground',
        icon && 'ps-2',
        className
      )}
      {...props}
    >
      {icon && <Icon size={16} icon={icon} className="text-foreground/50" />}
      {label}
    </span>
  )
}
