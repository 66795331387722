import { AnimatePresence, motion } from 'framer-motion'
import NextImage from 'next/image'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { OldButton, Container, Icon, Link, Text } from 'common/UI'
import { useBodyLock } from 'common/hooks/useBodyLock'
import {
  MenuEntryDividerStoryblok,
  MenuEntryStoryblok,
  TextLinkStoryblok,
} from 'common/types'
import { getImageAttributes } from 'common/utils/content'
import { dashedLine } from 'common/utils/style'
import { useDataContext } from 'lib/dataContext'
import { useTranslation } from 'lib/i18n'
import { CMSLink } from 'modules/shared'

type Props = {
  homeUrl: string
  onSearchClick: () => void
}

const CollapsibleMenu = ({
  item,
  isOpen,
  onOpen,
}: {
  item: MenuEntryStoryblok | TextLinkStoryblok
  isOpen: boolean
  onOpen: (id: string) => void
}) => {
  return (
    <>
      <MobileNavItem
        onClick={() => {
          onOpen(item._uid)
        }}
      >
        <Text as="h5" variant="eighteen/regular">
          {item?.label}
        </Text>
        <Icon
          icon={isOpen ? 'caret-up' : 'caret-down'}
          className="opacity-70"
        />
      </MobileNavItem>
      <AnimatePresence>
        {isOpen && (
          <Collapisable
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            transition={{
              type: 'spring',
              damping: 50,
              stiffness: 800,
            }}
          >
            <div>
              <NavLinks>
                {item.links?.map(
                  (
                    link:
                      | MenuEntryStoryblok
                      | TextLinkStoryblok
                      | MenuEntryDividerStoryblok
                  ) => {
                    if (link.component === 'menu-entry-divider') {
                      return
                    }
                    return (
                      <NavLink key={link._uid} variant="text" href={link.link}>
                        <Text as="span" variant="eighteen/regular">
                          {link.label}
                        </Text>
                      </NavLink>
                    )
                  }
                )}
              </NavLinks>
            </div>
          </Collapisable>
        )}
      </AnimatePresence>
    </>
  )
}

export const MobileNavBar = ({
  homeUrl,
  onSearchClick,
  ...props
}: Props): JSX.Element | null => {
  const router = useRouter()

  const { config, isSubSite } = useDataContext()
  const { i18n } = useTranslation()

  const [open, setOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)
  const [distanceFromTop, setDistanceFromTop] = useState(0)
  useBodyLock(open)

  const [openAccordion, setOpenAccordion] = useState<string | null>(null)

  useEffect(() => {
    const ref = menuRef.current
    setDistanceFromTop(ref?.getBoundingClientRect().top || 0)
  }, [open])

  useEffect(
    function closeOnNavigate() {
      const handleRouteChange = () => {
        setOpen(false)
      }

      router.events.on('routeChangeStart', handleRouteChange)

      return () => {
        router.events.off('routeChangeStart', handleRouteChange)
      }
    },
    [router.events]
  )

  if (!config || !config?.content) {
    return null
  }

  const { menu_logo, menu_links, cta_link, cta_label } = config.content

  return (
    <div css={{ position: 'relative' }} {...props}>
      <MobileTopNavContainer>
        <TopBar css={{ display: 'flex' }}>
          {menu_logo && menu_logo.filename && (
            <LogoHolder>
              <Link href={homeUrl}>
                <NextImage
                  {...getImageAttributes(menu_logo)}
                  style={{ objectFit: 'cover', height: '100%' }}
                  priority
                />
              </Link>
            </LogoHolder>
          )}
          <MenuButton
            onClick={() => setOpen((prev) => !prev)}
            size="small"
            leftIcon={open ? 'close' : 'hamburguer'}
            variant="ghost"
          />
        </TopBar>
      </MobileTopNavContainer>
      <AnimatePresence>
        {open && (
          <Menu
            ref={menuRef}
            initial={{ height: 0 }}
            animate={{
              height: `calc(100vh - ${distanceFromTop}px)`,
            }}
            exit={{ height: 0 }}
            transition={{ type: 'spring', damping: 50, stiffness: 800 }}
          >
            <MenuContainer>
              {cta_link && cta_label && (
                <CMSLink
                  variant={isSubSite ? 'accent' : 'solid'}
                  href={cta_link}
                >
                  {cta_label}
                </CMSLink>
              )}
              <FakeInput
                variant="ghost"
                css={{ marginTop: '1rem', marginBottom: '0.5rem' }}
                onClick={() => onSearchClick()}
              >
                <Icon icon="search" />
                <Text
                  as="span"
                  color="foreground.subtle"
                  css={{ marginLeft: '0.5rem' }}
                >
                  {i18n('search.searchWebsite')}
                </Text>
              </FakeInput>
              <nav>
                {menu_links?.map((item) => {
                  if (item.component === 'text-link') {
                    return (
                      <MobileNavItemWrapper>
                        <NavLink
                          key={item._uid}
                          variant="text"
                          href={item.link}
                          css={{ paddingLeft: 0, marginBottom: 0 }}
                        >
                          <Text as="span" variant="eighteen/regular">
                            {item.label}
                          </Text>
                        </NavLink>
                      </MobileNavItemWrapper>
                    )
                  }

                  return (
                    <MobileNavItemWrapper key={item._uid}>
                      <CollapsibleMenu
                        item={item}
                        isOpen={openAccordion === item._uid}
                        onOpen={(id) => {
                          setOpenAccordion(openAccordion === id ? null : id)
                        }}
                      />
                    </MobileNavItemWrapper>
                  )
                })}
              </nav>
            </MenuContainer>
            <img alt="" css={{ display: 'block' }} src="/assets/banner.png" />
          </Menu>
        )}
      </AnimatePresence>
    </div>
  )
}

const MenuContainer = styled(Container)`
  display: flex;
  flex-direction: column;
`

const Menu = styled(motion.div)`
  background: ${({ theme }) => theme.colors.background.default};
  position: absolute;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: calc(100% - 1px);
  z-index: ${({ theme }) => theme.zIndex.highest};
`

const MobileTopNavContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  padding: 0.75rem 1rem;
  background-color: ${({ theme }) => theme.colors.background.default};
  ${({ theme }) => dashedLine('bottom', theme.colors.foreground.default)}
`

const LogoHolder = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 2rem;
    width: auto;
  }
`

const MenuButton = styled(OldButton)`
  width: 2.5rem;
  height: 2.5rem;
`

const NavLink = styled(CMSLink)`
  display: block;
  padding: 0;
  padding-left: 1rem;
  margin-bottom: 0.125rem;
  height: auto;

  :not(:last-child) {
    padding-bottom: 1rem;
  }
`
const NavLinks = styled.ul`
  margin-top: 1rem;
  ${({ theme }) => dashedLine('left', theme.colors.foreground.default)}
  padding-bottom: 0.25rem;
`

const MobileNavItem = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Collapisable = styled(motion.div)`
  overflow: hidden;
`
const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
`

const FakeInput = styled(OldButton)`
  border-radius: 0.5rem;
  padding: 0.875rem 1rem;
  justify-content: flex-start;
`

const MobileNavItemWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`
