import { FAQPageJsonLd } from 'next-seo'
import { ISbStoryData } from 'storyblok-js-client'

import { FaqEntryStoryblok, PageStoryblok } from 'common/types'
import { richtextToString } from 'common/utils/content'
import { DynamicBlock } from 'modules/Blocks/DynamicBlock'
import { Layout } from 'modules/Layout/Layout'
import { Seo } from 'modules/shared/Seo'

type Props = {
  story: ISbStoryData<PageStoryblok>
}

const getFaqs = (
  body: ISbStoryData<PageStoryblok>['content']['body']
): FaqEntryStoryblok[] => {
  const faqs: FaqEntryStoryblok[] = []

  body?.forEach((block) => {
    if (block.component === 'faqs') faqs.push(...block.faqs)

    if (block.body) faqs.push(...getFaqs(block.body))
  })

  return faqs
}

export const StaticPage = ({ story }: Props): JSX.Element => {
  const faqs = getFaqs(story.content.body)

  return (
    <Layout>
      {faqs.length > 0 && (
        <FAQPageJsonLd
          mainEntity={faqs.map((faq) => ({
            questionName: faq.question,
            acceptedAnswerText: richtextToString(faq.answer),
          }))}
        />
      )}

      <Seo story={story} />

      {story.content?.body?.map((block) => (
        <DynamicBlock key={block._uid} block={block} />
      ))}
    </Layout>
  )
}
