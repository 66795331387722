import { AnchorHTMLAttributes, forwardRef, Ref } from 'react'
import styled from 'styled-components'

import { IconOptions, Icon } from './Icon'

export type LinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  leftIcon?: IconOptions
  rightIcon?: IconOptions
  isUnderlined?: boolean
}

export const Link = forwardRef(function Link(
  { leftIcon, rightIcon, isUnderlined, children, ...props }: LinkProps,
  ref: Ref<HTMLAnchorElement>
): JSX.Element {
  return (
    <StyledLink isUnderlined={isUnderlined} ref={ref} {...props}>
      {leftIcon && (
        <IconHolder hasSpacing={!!children} position="left">
          <Icon icon={leftIcon} />
        </IconHolder>
      )}
      {children}
      {rightIcon && (
        <IconHolder hasSpacing={!!children || !!leftIcon} position="right">
          <Icon icon={rightIcon} />
        </IconHolder>
      )}
    </StyledLink>
  )
})

const StyledLink = styled.a<{ isUnderlined?: boolean }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  font-weight: 400;

  color: inherit;
  text-decoration: ${({ isUnderlined }) =>
    isUnderlined ? 'underline' : 'none'};

  transition: opacity ${({ theme }) => theme.transitions.ease('150ms')};

  @media (hover: hover) {
    &:hover {
      opacity: 0.9;
    }
  }
`

const IconHolder = styled.span<{
  hasSpacing?: boolean
  position?: 'left' | 'right'
}>`
  display: inline-flex;
  align-items: center;
  ${({ hasSpacing, position }) =>
    hasSpacing &&
    position === 'left' &&
    `
      margin-right: 0.5rem;
    `}
  ${({ hasSpacing, position }) =>
    hasSpacing &&
    position === 'right' &&
    `
      margin-left: 0.5rem;
    `}
`
