import { GetStaticPaths, GetStaticProps } from 'next'
import Error from 'next/error'
import { useRouter } from 'next/router'
import { ISbStoryData } from 'storyblok-js-client'
import styled, { keyframes } from 'styled-components'

import { Breadcrumb, BREADCRUMBS_MIN_LEVEL } from 'common/UI/Breadcrumbs'
import {
  AlertStoryblok,
  BlogPostStoryblok,
  ConfigStoryblok,
  FloatingButtonsStoryblok,
  OpenEveningStoryblok,
  PageStoryblok,
  PatientStoryStoryblok,
  SatelliteClinicPageStoryblok,
  ServiceDetailPageStoryblok,
  TeamMemberDetailPageStoryblok,
} from 'common/types'
import { getStoryblokCacheValue } from 'common/utils/content'
import { useSearchAndScroll } from 'common/utils/search'
import { isValidString } from 'common/utils/string'
import {
  getInConfig,
  getConfigPaths,
  getRegions,
  getStory,
  getPagePaths,
  getLinks,
  getTranslationStrings,
  fetchEntriesData,
  EntriesData,
} from 'lib/api'
import { CurrencyProvider } from 'lib/currencyContext'
import { DataContext } from 'lib/dataContext'
import { useStoryblok } from 'lib/storyblok/useStoryblok'
import { BlogPostPage } from 'modules/Pages/BlogPostPage'
import { OpenEveningPage } from 'modules/Pages/OpenEveningPage'
import { PatientStoryPage } from 'modules/Pages/PatientStoryPage'
import { SatelliteClinicPage } from 'modules/Pages/SatelliteClinicPage'
import { ServiceDetailPage } from 'modules/Pages/ServiceDetailPage'
import { StaticPage } from 'modules/Pages/StaticPage'
import { TeamMemberDetailPage } from 'modules/Pages/TeamMemberDetailPage'

export const RESOLVE_RELATIONS: string[] = [
  'selected-clinics.items',
  'selected-blog-posts.items',
  'selected-patient-stories.items',
  'blog-post.services',
  'patient-story.categories',
  'where-to-find-us.clinic',
]

type PageProps = {
  locale: string
  messages: Record<string, string>
  story: ISbStoryData
  config: ISbStoryData<ConfigStoryblok> | null
  alert: ISbStoryData<AlertStoryblok> | null
  avoidAlerts: boolean
  floating: ISbStoryData<FloatingButtonsStoryblok> | null
  regions: { code: string; name: string }[]
  alternates: string[]
  isSubSite: boolean
  allEntriesData: EntriesData
  latestEntriesData: EntriesData
  breadcrumbs?: Breadcrumb[] | null
}

const StaticPageWrapper: React.FC<PageProps> = ({
  story,
  config,
  alert,
  regions,
  floating,
  alternates,
  isSubSite,
  allEntriesData,
  latestEntriesData,
  breadcrumbs,
}) => {
  const { isFallback, query } = useRouter()
  const search = isValidString(query.search) ? query.search : null
  useSearchAndScroll(search)

  if (isFallback) {
    return <PageLoader />
  }

  return (
    <DataContext.Provider
      value={{
        storyId: story.id,
        storyUuid: story.uuid,
        allEntriesData,
        latestEntriesData,
        regions,
        config,
        alert,
        avoidAlerts: story.content.avoid_alerts || false,
        floating,
        alternates,
        isSubSite,
        breadcrumbs,
      }}
    >
      <CurrencyProvider initialCurrency="EUR">
        <Page story={story} />
      </CurrencyProvider>
    </DataContext.Provider>
  )
}

const Page: React.FC<{
  story: NonNullable<PageProps['story']>
}> = ({ story: propsStory }) => {
  const story = useStoryblok(propsStory, RESOLVE_RELATIONS)
  const { query } = useRouter()
  const search = isValidString(query.search) ? query.search : null
  useSearchAndScroll(search)

  switch (story.content.component) {
    case 'page':
      return <StaticPage story={story as ISbStoryData<PageStoryblok>} />
    case 'blog-post':
      return <BlogPostPage story={story as ISbStoryData<BlogPostStoryblok>} />
    case 'patient-story':
      return (
        <PatientStoryPage
          story={story as ISbStoryData<PatientStoryStoryblok>}
        />
      )
    case 'open-evening':
      return (
        <OpenEveningPage story={story as ISbStoryData<OpenEveningStoryblok>} />
      )
    case 'service-detail-page':
      return (
        <ServiceDetailPage
          story={story as ISbStoryData<ServiceDetailPageStoryblok>}
        />
      )
    case 'team-member-detail-page':
      return (
        <TeamMemberDetailPage
          story={story as ISbStoryData<TeamMemberDetailPageStoryblok>}
        />
      )
    case 'satellite-clinic-page':
      return (
        <SatelliteClinicPage
          story={story as ISbStoryData<SatelliteClinicPageStoryblok>}
        />
      )

    default:
      throw new Error({ statusCode: 404 })
  }
}

export default StaticPageWrapper

export const getStaticProps: GetStaticProps<
  PageProps,
  { lang: string; slug: string | string[] }
> = async (ctx) => {
  try {
    const lang = ctx.params?.lang

    const slug = Array.isArray(ctx.params?.slug)
      ? ctx.params?.slug.join('/')
      : ctx.params?.slug || ''
    const fullSlug = `${lang}/${slug}`

    const params = ctx.preview
      ? ({
          version: 'draft',
          cv: getStoryblokCacheValue(ctx.preview),
        } as const)
      : ({
          version: 'published',
          cv: getStoryblokCacheValue(ctx.preview),
        } as const)

    /**
     * Story
     */
    const story: ISbStoryData | null = await getStory(fullSlug, {
      resolve_links: 'url',
      resolve_relations: RESOLVE_RELATIONS.join(','),
      ...params,
    })

    if (!story) {
      return {
        notFound: true,
        revalidate: 1,
      }
    }

    const isPage = [
      'page',
      'blog-post',
      'patient-story',
      'open-evening',
      'service-detail-page',
      'team-member-detail-page',
      'satellite-clinic-page',
    ].includes(story?.content.component || '')
    const pageStory = isPage ? story : null

    if (!pageStory) {
      console.warn(
        `Returned 404 for page '${fullSlug}' as it is from an unexpected content type: '${story?.content.component}'`
      )

      return {
        notFound: true,
        revalidate: 1,
      }
    }

    const links = await getLinks(params)
    const paths = await getConfigPaths(links, fullSlug)

    const isSubSite = paths.length > 1

    const alternates = story ? story.alternates.map((alt) => alt.full_slug) : []

    const config = await getInConfig<ISbStoryData<ConfigStoryblok>>(
      'config',
      paths,
      params
    )

    const alert = await getInConfig<ISbStoryData<AlertStoryblok>>(
      'alert',
      [paths[0]], // just the first match (closest to the current page)
      params
    )

    const floating = await getInConfig<ISbStoryData<FloatingButtonsStoryblok>>(
      'floating-buttons',
      paths,
      params
    )

    const entriesLanguage = isSubSite ? fullSlug : lang

    const allEntriesData = await fetchEntriesData({
      story,
      lang: entriesLanguage,
      blockType: 'allEntriesData',
    })

    const latestEntriesData = await fetchEntriesData({
      story,
      lang: entriesLanguage,
      blockType: 'latestEntriesData',
    })

    /**
     * Regions – name: de-at, value: Österreich (Deutsch)
     */
    const regions = await getRegions()

    /**
     * i18n
     */
    const [locale] = (lang || 'en').split('-')
    const messages = await getTranslationStrings(locale)

    const avoidAlerts = pageStory.content.avoid_alerts || false

    /**
     * Breadcrumbs
     */
    const urlLevels = fullSlug.split('/').filter(Boolean)
    let breadcrumbs = null

    if (urlLevels.length > BREADCRUMBS_MIN_LEVEL) {
      const breadcrumbsPromises = urlLevels.map(async (_, index) => {
        const slug = urlLevels.slice(0, index + 1).join('/')
        let story = null
        try {
          story = await getStory(slug, {
            version: ctx.preview ? 'draft' : 'published',
            cv: getStoryblokCacheValue(ctx.preview),
          })
        } catch (error) {
          console.error(`Story with '${slug}' does not exist`)
        }

        const storyData = {
          breadcrumb: story?.content.breadcrumb_title || story?.name,
          href: `/${slug}`,
        }

        return storyData
      })

      const resolvedBreadcrumbs = await Promise.all(breadcrumbsPromises)
      breadcrumbs = resolvedBreadcrumbs.filter(
        (v): v is { breadcrumb: string; href: string } =>
          v?.breadcrumb !== undefined
      )
    }

    return {
      props: {
        locale,
        messages,
        story: pageStory,
        config,
        alert,
        avoidAlerts,
        regions,
        floating,
        alternates,
        isSubSite,
        allEntriesData,
        latestEntriesData,
        breadcrumbs,
      },
      revalidate: 10,
    }
  } catch (error) {
    return {
      notFound: true,
    }
  }
}

export const getStaticPaths: GetStaticPaths = async () => {
  const paths =
    process.env.NODE_ENV === 'development' ? [] : await getPagePaths()

  return {
    paths,
    // we need fallback to be able to preview unpublished pages on the CMS
    fallback: 'blocking',
  }
}

const rotate360 = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`

const PageLoader = styled.div`
  box-sizing: border-box;

  width: 1.5rem;
  height: 1.5rem;
  border-radius: 100%;

  position: absolute;
  top: calc(50% - 0.75rem);
  left: calc(50% - 0.75rem);

  border: 3px solid ${({ theme }) => theme.colors.palette.pink.default};

  border-top-color: transparent;

  animation: ${rotate360} 2s linear infinite;
`
