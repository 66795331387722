import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import { ClinicLocationStoryblok, ClinicStoryblok } from 'common/types'
import { getStoryblokCacheValue } from 'common/utils/content'
import { Storyblok } from 'lib/storyblok'

export type Clinic = {
  uuid: string
  slug: string
  full_slug: string
  content: ClinicStoryblok
}

export type ClinicLocation = {
  id: string
  slug: string
  content: ClinicLocationStoryblok
}

type Return<T> = {
  data: T[]
  isError: boolean
  isLoading: boolean
}

export const useFetchClinics = (): Return<Clinic> => {
  const { isPreview } = useRouter()
  const [data, setData] = useState<Clinic[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const fetch = useCallback(async () => {
    setIsLoading(true)
    setIsError(false)

    try {
      const {
        data: { stories },
      } = await Storyblok.get('cdn/stories', {
        version: isPreview ? 'draft' : 'published',
        sort_by: 'first_published_at:desc',
        content_type: 'clinic',
        per_page: 100,
        page: 1,
        cv: getStoryblokCacheValue(isPreview),
      })

      setIsLoading(false)
      setData(stories)
    } catch {
      setIsError(true)
    }
  }, [isPreview])

  useEffect(() => {
    fetch()
  }, [fetch])

  return { data, isLoading, isError }
}

export const useFetchClinicLocations = (): Return<ClinicLocation> => {
  const { isPreview } = useRouter()
  const [data, setData] = useState<ClinicLocation[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)

  const fetch = useCallback(async () => {
    setIsLoading(true)
    setIsError(false)

    await new Promise((r) => setTimeout(r, 6000))
    try {
      const {
        data: { stories },
      } = await Storyblok.get('cdn/stories', {
        version: isPreview ? 'draft' : 'published',
        sort_by: 'first_published_at:desc',
        content_type: 'clinic-location',
        per_page: 100,
        page: 1,
        cv: getStoryblokCacheValue(isPreview),
      })

      setIsLoading(false)
      setData(stories)
    } catch {
      setIsError(true)
    }
  }, [isPreview])

  useEffect(() => {
    fetch()
  }, [fetch])

  return { data, isLoading, isError }
}
